export const iconIndicatorColor = {
    amountRequest: "93 135 255",
    amountRejected:"252 100 45",
    amountRepaid:"85 208 126",
    branch:"244 180 0",
    apiHealth:"0 44 141",
    aadhar:"181 45 45",
    pan:"161 110 9",
    revenue:"108 38 179",
    profit:'85 208 126',
    moneyGiven:"62 156 53",
    nonRecover:"231 175 33",
    newLoan:"93 135 255",
    loanProcess:"231 175 33",
    loanReject:"252 100 45",
    attendance:"181 45 45",
    paymentTerm:"108 38 179",
    loanAmount:'85 208 126',
    annualIncome:"62 156 53",
    loanType:"231 175 33",
    applicationNo:"93 135 255",
}
